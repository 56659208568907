<template>
  <div class="mx-8">
    <v-subheader class="py-0 d-flex justify-space-between">
      <h3>AKSES {{ detailUserGroup.subgroup }}</h3>
      <v-dialog v-model="dialogAccess" width="700">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="success" v-bind="attrs" v-on="on"> Tambah Akses </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 success white--text">
            Tambah Akses
          </v-card-title>

          <br />

          <v-card-text>
            <v-card
              v-for="(modul, index) in listModules"
              :key="index"
              outlined
              class="mb-2"
            >
              <v-card-text>
                <v-checkbox
                  v-model="akses[index]"
                  :value="modul._id"
                  :label="modul.akses"
                ></v-checkbox>

                <v-row>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      :disabled="!akses[index]"
                      v-model="methods[index]"
                      value="GET"
                      label="Lihat"
                      multiple
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      :disabled="!akses[index]"
                      v-model="methods[index]"
                      value="POST"
                      label="Input"
                      multiple
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      :disabled="!akses[index]"
                      v-model="methods[index]"
                      value="PUT"
                      label="Edit"
                      multiple
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox
                      :disabled="!akses[index]"
                      v-model="methods[index]"
                      value="DELETE"
                      label="Hapus"
                      multiple
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogAccess = false">
              Batal
            </v-btn>
            <v-btn color="primary" text @click="save()"> Simpan </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-subheader>

    <div class="mb-2"></div>

    <v-data-table
      :headers="headers"
      :items="detailUserGroup.akses"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.nomor="{ item }">
        <span>{{ detailUserGroup.akses.indexOf(item) + 1 }}</span>
      </template>

      <template v-slot:item.akses="{ item }">
        <span>{{ item._id }} - {{ item.akses }}</span>
      </template>

      <template v-slot:item.method="{ item }">
        <span>{{ item.method | replaceString }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              @click="showDialogDelete(item)"
              >mdi-delete</v-icon
            >
          </template>
          <span>Hapus</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        <label>Tidak ada data</label>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <v-dialog v-model="dialogDelete" width="500">
      <v-card>
        <v-card-title class="text-h5 primary white--text">
          Hapus Akses
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus akses
          {{ dataDelete.akses }} ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="onDelete()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      headers: [
        { text: "No.", value: "nomor", width: "70px", sortable: false },
        { text: "Akses", value: "akses" },
        { text: "Metode", value: "method", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ],
      akses: [],
      methods: [],
      dialogAccess: false,
      snackbar: false,
      snackbarText: "",
      dialogDelete: false,
      dataDelete: {
        modul: "",
        akses: "",
      },
    };
  },
  watch: {
    dialogAccess() {
      if (!this.dialogAccess) {
        this.akses = [];
        this.methods = [];
      }
    },
  },
  computed: {
    detailUserGroup() {
      return this.$store.getters.detailUserGroup;
    },
    listModules() {
      return this.$store.getters.listModules;
    },
  },
  created() {
    this.$store.dispatch("getListModules");
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("getDetailUserGroup", this.id);
    },
    save() {
      let daftarAkses = [];
      for (let index = 0; index < this.akses.length; index++) {
        if (this.akses[index]) {
          daftarAkses.push(
            JSON.stringify({
              id: this.akses[index],
              method: this.methods[index],
            })
          );
        }
      }

      const data = {
        id: this.id,
        akses: daftarAkses,
      };

      this.$store.dispatch("setAccess", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogAccess = false;
        }
      });
    },
    showDialogDelete(item) {
      this.dialogDelete = true;
      this.dataDelete.modul = item._id;
      this.dataDelete.akses = item.akses;
    },
    onDelete() {
      const data = {
        id: this.id,
        modul: this.dataDelete.modul,
        akses: this.dataDelete.akses,
      };
      this.$store.dispatch("deleteAccess", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.init();
        }
      });
    },
  },
};
</script>